.mrc-simple-card {
  @apply mx-auto flex w-full flex-col gap-4 px-5 py-10;

  .wrapper {
    @apply flex w-full flex-col items-center gap-6 rounded-3xl bg-gray-100 py-16 text-center;
  }
  .button {
    @apply inline-flex rounded-full bg-primary px-4 py-2 text-base text-white hover:text-white;
  }
}
